.shops-wrapper {
    background: var(--second-color);
    margin: 0;
    padding: 20px 10px;
}

.shops-wrapper h2 {
    margin: 0 auto 20px;
    text-align: center;
    color: var(--main-color);
}

.shops-container {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    margin: 0 auto;
}

.shop-item-wrapper {
    position: relative;
    margin-bottom: 30px;
    background: var(--main-color);
    color: var(--second-text-color);
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
}

.shop-item-wrapper:last-child::after {
    display: none;
}

.shop-item-wrapper:last-child {
    margin-bottom: 0;
}

.shop-item-container {
    display: flex;
    flex-direction: column;
}

.shop-item-container .shop-desc {
    padding: 5px 10px 10px;
}

.shop-item-container .shop-desc p {
    margin: 0;
    line-height: 1.2;
}

.shop-desc h3 {
    font-size: 1.25rem;
    margin: 0;
    line-height: 1.1;
}

.shop-item-container .shop-excerpt {
    font-size: 0.9rem;
    margin-bottom: 5px;
}

.shop-item-container .shop-content {
    font-size: 1rem;
}

.shop-item-container .image-wrapper {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    overflow: hidden;
}
.image-wrapper img {
    display: block;
    width: 100%;
    height: auto;
}

.shop-item-container .btn.btn-secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    border-color: var(--second-text-color);
    border-radius: var(--border-radius);
    padding: 10px 40px;
    margin-bottom: 0;
    min-height: 48px;
    position: relative;
}

.shop-item-container .btn.btn-secondary:hover {
    border-color: var(--second-text-color);
}

.shop-item-container .btn.btn-secondary.route .ic-route {
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    background: #fff;
    mask: url('../../assets/icons/route-ic.svg') no-repeat center / contain;
    -webkit-mask: url('../../assets/icons/route-ic.svg') no-repeat center / contain; 
}


@media (min-width: 600px) {
    .shops-wrapper {
        margin: 0;
        padding: 20px 40px;
    }
}

@media (min-width: 640px) {
    .shop-item-wrapper {
        background: none;
        border-radius: 0;
        color: var(--main-color);
    }

    .shop-item-container {
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .shop-item-wrapper:nth-child(even) .shop-item-container {
        flex-direction: row-reverse;
    }

    .shop-item-container .image-wrapper {
        width: 55%;
        border-radius: var(--border-radius);
    }

    .shop-item-container .shop-desc {
        width: 45%;
    }

    .shop-item-container .btn.btn-secondary {
        border-color: var(--main-color);
        font-size: 1.1rem;
        max-width: 280px;
        padding: 10px 40px 10px 25px;
    }
}
