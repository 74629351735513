.main-screen {
    display: flex;
    height: 100vh;
    box-sizing: border-box;
    background: var(--main-color) url('../../../assets/images/first-bg.webp') center top no-repeat;
    background-size: cover;
}

.main-screen-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px 10px 70px;
}

.main-screen-slider {
    margin-top: 50px;
}

.main-screen-slider-container {
    width: 100%;
}

.main-screen-slider-container {
    flex: 1;
    align-content: center;
}

.main-screen-container .promo {
    max-width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    background: rgba(20, 22, 21, .4);
    font-size: 1.6rem;
    line-height: 1.1;
    text-align: center;
}

.main-screen-container .promo strong {
    color: var(--second-color);
}

.next-screen-btn-wrapper {
    width: 100%;
}

.next-screen-btn {
    display: block;
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    margin-top: 20px;
    cursor: pointer;
}

.next-screen-btn:focus-visible {
    outline: none;
}

.next-screen-btn .inner-btn {
    position: relative;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    background: var(--second-color);
    border-radius: 50%;
}

.next-screen-btn .inner-btn .ic-next {
    position: absolute;
    top: 5px;
    bottom: 0;
    left: 3px;
    right: 3px;
    background: var(--main-color);
    mask: url('../../../assets/icons/arrow-down.svg') no-repeat center / contain;
    -webkit-mask: url('../../../assets/icons/arrow-down.svg') no-repeat center / contain;
}

@media (min-width: 768px) {
    .main-screen {
        min-height: 100vh;
        background: var(--main-color) url('../../../assets/images/first-bg-d.webp') center no-repeat;
        background-size: cover;
    }

    .main-screen-container {
        padding: 20px 40px;
    }
  
    .main-screen-container .promo {
       max-width: 50%;
       margin-left: 0;
    }
}
