.social-links-wrapper a {
    display: inline-block;
}
.soc-ic {
    display: block;
    width: 24px;
    height: 24px;
}

.soc-ic.telegram {
    background: #24a1de;
    mask: url('../../../assets/icons/telegram-ic.svg') no-repeat center / contain;
    -webkit-mask: url('../../../assets/icons/telegram-ic.svg') no-repeat center / contain;
}

.soc-ic.telegram:hover {
    background-color: var(--second-color);
}