header {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(20, 22, 21, .8);
    padding: 5px 15px;
    height: 66px;
    box-sizing: border-box;
    z-index: 50;
}

.logo {
    width: 260px;
    max-width: 55%;
    display: block;
    margin: 0 auto;
}

.logo img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
}

.header-content {
    display: flex;
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box;
    margin: 0 auto;
}

@media (min-width: 400px) {
    header {
        height: 76px;
    }
}

@media (min-width: 600px) {
    header {
        padding: 10px 0;
    }

    .header-content {
        box-sizing: border-box;
    }
}

.beer-icon-wrapper {
    display: block;
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -19px;
    width: 40px;
    height: 40px;
}

.beer-icon {
    display: block;
    position: absolute;
    width: 38px;
    height: 38px;
    background: url("../../assets/icons/beer-mug-ic.webp") center no-repeat;
    background-size: 70%;
    transition: transform 0.1s ease;
}

@media (min-width: 1024px) {
    .header-content {
        justify-content: space-between;
        padding: 0 40px;
    }

    .logo {
        margin: -1px 0 0;
    }

    .beer-icon-wrapper {
        display: none;
    }

}