.homepage .slider-products {
    order: 5;
}

.homepage .category-list {
    order: 10;
}

@media (min-width: 768px) {
    .homepage .slider-products {
        order: 10;
    }

    .homepage .category-list {
        order: 5;
    }
}