.content-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box;
    padding: 0 10px;
}

.content-wrapper figure {
    margin: 0 auto;
}

.content-wrapper figure img {
    height: auto;
}

.content-wrapper {
    line-height: 1.2;
}

.content-wrapper p {
    width: 100%;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 10px;
}

.content-wrapper h2 {
    font-size: 2rem;
    margin: 10px 0;
}

.content-wrapper h3 {
    font-size: 1.75rem;
    margin: 10px 0;
}

.content-wrapper h4 {
    font-size: 1.5rem;
    margin: 10px 0;
}

.content-wrapper h5 {
    font-size: 1.25rem;
    margin: 10px 0;
}

.content-wrapper h6 {
    font-size: 1rem;
    margin: 10px 0; 
}

.content-wrapper ul,
.content-wrapper ol {
    padding-left: 20px;
    margin: 0;
}

.content-wrapper ul {
    list-style: disc;
}

.content-wrapper .image-wrapper {
    margin: 0 auto;
}

.content-wrapper ul li {
    margin-bottom: 5px;
}

.content-wrapper blockquote {
    font-size: 1.125rem;
    font-style: italic;
    margin: 0 -10px;
    background: var(--main-color);
    color: var(--second-text-color);
    border: 10px solid var(--second-color);
    padding: 10px 20px;
    box-sizing: border-box;
}

@media (min-width: 768px) {
    .content-wrapper {
        gap: 10px;
        margin: 0 auto;
        max-width: 1440px;
        padding: 20px 40px 20px;
    }

    .content-wrapper p {
        margin-bottom: 0;
    }
}