footer {
    border-top: 1px solid var(--second-color);
    background: var(--main-color);
    align-self: flex-end;
    width: 100%;
}

.footer-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
}

footer .top-level {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.footer-addresses {
    flex: 1;
    padding-right: 5px;
}

.shop-address-city {
    color: var(--second-color);
}

footer .top-level ul {
    display: flex;
    flex-direction: column;
}

footer .top-level ul li {
    margin-bottom: 10px;
    text-align: right;
    font-size: 1.2rem;
}

footer .top-level ul li a.active,
footer .top-level ul li a:hover {
    color: var(--second-color);
}

footer .middle-level {
    display: flex;
    justify-content: end;
}

@media (min-width: 768px) {
    footer .top-level ul li {
        margin-bottom: 5px;
    }

    .footer-container {
        padding: 20px 40px;
    }
}
