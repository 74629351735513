.breadcrumbs-wrapper {
    width: 100%;
    background: var(--second-color);
    color: var(--main-color);
    margin: 0;
    padding: 5px 10px;
    font-size: 0.8rem;
}

.breadcrumbs-wrapper .breadcrumbs {
    margin: 0 auto;
    box-sizing: border-box;
}

.breadcrumbs a {
    position: relative;
    text-decoration: none;
    margin-right: 20px;
}

.breadcrumbs a:hover {
    text-decoration: underline;
}

.breadcrumbs a:after {
    content: '>';
    position: absolute;
    top: 1px;
    right: -13px;
}

@media (min-width: 600px) {
    .breadcrumbs-wrapper {
        margin: 0;
        padding: 7px 0;
    }

    .breadcrumbs-wrapper .breadcrumbs {
        max-width: 1440px;
        padding: 0 40px;
    }
}