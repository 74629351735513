.product-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    position: relative;
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box;
    padding: 0 10px;
    margin-bottom: 20px;
}

.product-list-item {
    overflow: hidden;
    border: 2px solid var(--second-text-color);
    border-radius: var(--border-radius);
    background: #fff;
}

.product-list-item:hover {
    border-color: var(--second-color);
}

.product-image-wrapper {
    position: relative;
    padding-bottom: 100%;
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.product-image-wrapper picture img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.product-list-item .product-item-title {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-color);
    color: var(--second-text-color);
    padding: 5px;
    height: 58px;
}

.product-list-item .product-item-title .text {
    display: flex;
    text-align: center;
    font-size: 1rem;
    line-height: 1;
    height: auto;
    max-height: 3rem;
    overflow: hidden;
}

.page-content.pdp-page {
    display: grid;
}

.pdp-photo-wrapper {
    width: 100vw;
}

.pdp-main-wrapper {
    box-sizing: border-box;
    background: var(--main-color);
    color: var(--second-text-color);
}

.products-page h1 {
    max-width: 1440px;
    margin: 10px auto 20px;
    box-sizing: border-box;
    padding: 0 10px;
}

.pdp-main-info {
    text-align: center;
    padding: 0 10px;
}

.pdp-main-info .short-pdp-desc {
    text-align: center;
}

.pdp-main-info .short-pdp-desc p {
    margin: 0 0 10px;
}

@media (min-width: 768px) {
    .products-page .category-list {
        order: 1;
    }

    .products-page h1 {
        padding: 0 40px;
        order: 2;
    }

    .products-page .notice-message {
        order: 3;
    }

    .products-page .product-list {
        order: 4;
        padding: 0 40px;
    }

    .products-page .pagination {
        order: 5;
    }

    .pdp-main-wrapper {
        width: 100vw;
    }

    .pdp-main-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
        margin: 0 auto;
        max-width: 1440px;
        padding: 20px 40px 20px;
        box-sizing: border-box;
    }

    .pdp-photo-wrapper {
        width: 100%;
    }

    .pdp-photo-wrapper .product-image-wrapper {
        border-radius: 20px;
    }

    .pdp-main-info {
        align-content: center;
        padding: 10px 20px;
    }
}

@media (min-width: 800px) {
    .product-list {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px;
    }
}

@media (min-width: 1024px) {
    .product-list {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}