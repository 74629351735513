.blog-wrapper {
    margin: 0;
    padding: 0;
}

.blog-wrapper h1 {
    margin-top: 10px;
}

.blog-wrapper h2 {
    font-size: 1.4rem;
    line-height: 1.1;
}

.blog-container {
    box-sizing: border-box;
    max-width: 1280px;
    margin: 0 auto;
    padding: 10px;
}

.post-list {
    margin-bottom: 20px;
    text-align: center;
}

.post-list li {
    margin-bottom: 10px;
}

.short-post-desc {
    position: relative;
    height: 4.4rem;
    overflow: hidden;
    line-height: 1.1;
    margin-bottom: 10px;
}

.short-post-desc:after {
    position: absolute;
    bottom: 0;
    height: 1.1rem;
    left: 0;
    right: 0;
    content: '';
    background: var(--main-color);
    filter: blur(12px);
}

.short-post-desc p {
    margin: 0;
}

.post-list .image-wrapper {
    margin: 0 -10px;
}

@media (min-width: 768px) {
    .blog-wrapper h2 {
        height: 3.08rem;
        overflow: hidden;
    }

    .blog-container {
        padding: 20px 40px;
    }

    .post-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .post-list .image-wrapper {
        margin: 0;
        position: relative;
        padding-top: 70%;
        overflow: hidden;
        border-radius: 10px;
    }

    .post-list .image-wrapper img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
    }
}
