.pagination {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto 20px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: .9rem;
}

.pagination a {
    display: inline-block;
    border: 2px solid var(--second-text-color);;
    text-align: center;
    border-radius: var(--btn-border-radius);
    text-decoration: none;
    font-weight: bold;
    margin: 0 5px;
    color: var(--second-text-color);;
    width: 30px;
    height: 30px;
    line-height: 26px;
    box-sizing: border-box;
}

.pagination a.active {
    border-color: var(--second-color);
    color: var(--second-color);
}

@media (min-width: 768px) {
    .pagination {
        padding: 0 40px;
    }
}