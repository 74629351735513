.menu-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    margin-top: -20px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 3;
}

.menu-btn.active .bar.upper-bar,
.menu-btn.active .bar.lower-bar {
    transform: translatey(0px);
}

.menu-btn.active .bar.upper-bar {
    transform: rotate(45deg);
}

.menu-btn.active .bar.lower-bar {
    transform: rotate(-45deg);
}

.menu-btn.active .bar.middle-bar {
    transform: translatex(+500px);
}

.bar {
    width: 80%;
    height: 2px;
    background-color: var(--second-text-color);
    position: absolute;
    transition: all 0.3s ease-out;
}

.bar.upper-bar {
    transform: translatey(-10px);
}

.bar.middle-bar {
    background-color: var(--second-color);
}

.bar.lower-bar {
    transform: translatey(10px);
}

.nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    left: -100%;
    top: 0;
    bottom: 0;
    background: var(--main-color);
    color: var(--second-text-color);
    transition: left .3s;
}

.nav li a {
    display: block;
    text-align: center;
    padding: 10px;
    color: var(--second-text-color);
    font-size: 1.6rem;
    text-decoration: none;
    font-weight: bold;
}

.nav li a.active,
.nav li a:hover {
    color: var(--second-color);
}

.nav.active {
    left: 0;
}

@media (min-width: 1024px) {
    .menu-btn {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        left: auto;
        background: transparent;
    }

    .nav ul {
        display: flex;
    }

    .nav li a {
        padding: 0;
        margin: 0 10px;
        font-size: 1rem;
        font-weight: normal;
    }
}