body {
    font-size: var(--text-size);
    line-height: 1.2;
    box-sizing: border-box;
    background-color: var(--main-color);
    color: var(--second-text-color);
}

body.homepage {
    padding-top: 0;
}

.page-wrapper {
    overflow: hidden;
}

main {
    margin: 0 auto;
    padding: 0;
}

address {
    font-style: normal;
}

h1 {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 2rem;
}

h2 {
    font-size: 1.8rem;
    margin: 10px 0;
}

h3 {
    font-size: 1.75rem;
    margin: 10px 0;
}

h4 {
    font-size: 1.5rem;
    margin: 10px 0;
}

h5 {
    font-size: 1.25rem;
    margin: 10px 0;
}

h6 {
    font-size: 1rem;
    margin: 10px 0;
}

.page-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 66px;
}

.page-content.homepage {
    padding-top: 0;
}

img {
    max-width: 100%;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

a {
    color: var(--secpnd-text-color);
    text-decoration: none;
}

a:focus-visible {
    outline: none;
}

.btn {
    all: unset;
    display: inline-block;
    cursor: pointer;
    margin: 10px auto 20px;
}

.btn:focus-visible {
    outline: none;
}

.btn.btn-primary {
    background: none;
    color: var(--second-text-color);
    font-size: 1.2rem;
    padding: 10px 30px;
    border: 2px solid var(--second-text-color);
    border-radius: var(--border-radius);
}

.btn.btn-primary:hover {
    border-color: var(--second-color);
}

.btn.btn-secondary {
    background: var(--main-color);
    color: var(--second-text-color);
    font-size: 1.2rem;
    padding: 10px 30px;
    border: 2px solid var(--main-color);
}

@media (min-width: 400px) {
    .page-content {
        padding-top: 76px;
    }
}

@media (min-width: 600px) {
    main {
        padding: 0;
    }
}