.slider-products {
    width: 100vw;
    padding: 0 0 20px;
    box-sizing: border-box;
    margin: 0 auto;
}

.swiper-slide a {
    text-decoration: none;
}

.product-slider-item {
    text-decoration: none;
}

.product-slider-item .product-item-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;
    background: var(--main-color);
    color: var(--second-text-color);
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1;
    min-height: 2rem;
}

@media (min-width: 640px) {
    .slider-products {
        width: 100%;
        max-width: 1440px;
        padding: 20px 40px;
    }

    .product-slider-item .product-image-wrapper {
        border-radius: 10px;
    }
}