.category-list {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px 10px;
}

.category-list ul {
    margin: 0 auto;
    max-width: 1440px;
    box-sizing: border-box;
}

.category-list ul li {
    margin-bottom: 10px;
}

.category-list ul li a {
    display: block;
    padding: 12px 10px;
    text-align: center;
    border: 2px solid var(--second-text-color);
    color: var(--second-text-color);
    text-decoration: none;
    border-radius: var(--btn-border-radius);
    font-size: var(--menu-text-size);
    font-weight: bold;
}

.category-list ul li a.active {
    border-color: var(--second-color);
    color: var(--second-color);
}

@media (min-width: 500px) {
    .category-list ul {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10px;
    }

    .category-list ul li {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .category-list ul {
        grid-template-columns: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0;
        padding: 10px 40px;
    }

    .category-list ul li a {
        background: none;
        border: none;
        padding: 5px 10px;
        border-radius: 0;
        font-size: .9rem;
        color: var(--second-text-color);
        font-weight: normal;
    }

    .category-list ul li a:hover {
        text-decoration: underline;
    }

    .category-list ul li a.active {
        background: none;
        border: none;
        font-weight: bold;
    }
}