.loading-block {
    z-index: 30;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;
    right: -10px;
    background: rgba(0, 0, 0, .4);
    color: #ffffff;
    text-align: center;
}

.loading-block span {
    display: block;
    margin-top: 30px;
}

.loader-line {
    height: 4px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: var(--main-color);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    z-index: 60;
}

.loader-line:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 4px;
    width: 40%;
    background-color: var(--second-color);
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
}
